import axios from 'axios';
import i18next from 'i18next';

import generateTraceParent from '@/utils/generateTraceParent';

const axiosInstance = axios.create({
  baseURL: '/',
});

const liveServerAxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_CAMERAFI_API_URL,
});

const gateWayAxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_CAMERAFI_API_GATEWAY_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers['traceparent'] = generateTraceParent();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const getDeletionToken = async (idToken: string) =>
  await axiosInstance.get<{
    token: string;
  }>('/api/token-for-delete', {
    headers: {
      Authorization: 'Bearer ' + idToken,
    },
  });

const deleteUser = async ({
  uid,
  refreshToken,
  tokenForDelete,
}: {
  uid: string;
  refreshToken?: string;
  tokenForDelete: string;
}) =>
  await axiosInstance.delete('/api/users', {
    params: {
      uid,
      refreshToken,
    },
    headers: {
      Authorization: `Bearer ${tokenForDelete}`,
    },
  });

const getUser = async (idToken: string) =>
  await axiosInstance.get<{
    uid: string;
    userName: string;
  }>('/api/user', {
    headers: {
      Authorization: 'Bearer ' + idToken,
    },
  });

const createCustomToken = async ({
  idToken,
  params,
}: {
  idToken: string;
  params: {
    lastVisitedService?: string;
    isNewUser?: string;
    gaClientId?: string;
  };
}) =>
  await axiosInstance.get<{
    token: string;
  }>('/api/login', {
    headers: {
      Authorization: 'Bearer ' + idToken,
    },
    params,
  });

const updateUserName = async ({
  idToken,
  nickname,
}: {
  idToken: string;
  nickname: string;
}) =>
  await axiosInstance.get('/api/update-username', {
    headers: {
      Authorization: 'Bearer ' + idToken,
    },
    params: {
      username: nickname,
    },
  });

const verifyEmail = async (idToken: string) =>
  await axiosInstance.get('/api/verify-email', {
    headers: {
      Authorization: 'Bearer ' + idToken,
    },
    params: {
      language: i18next.t('languageParameter'),
    },
  });

const getUserInfoByUid = async ({
  uid,
  token,
}: {
  uid: string;
  token: string;
}) =>
  await liveServerAxiosInstance.get<{
    users: {
      nickname: string;
    }[];
  }>('/api/v1/users', {
    params: {
      uid,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const getChannelInfo = async ({
  handle,
  idToken,
}: {
  handle: string;
  idToken: string;
}) =>
  await gateWayAxiosInstance.get<{
    privacy?: {
      birthday: string;
      gender: string;
    };
    category?: string;
  }>(`/studio/api/v1/channels/${handle}`, {
    headers: {
      Authorization: 'Bearer ' + idToken,
    },
  });

export {
  createCustomToken,
  deleteUser,
  getUser,
  getDeletionToken,
  updateUserName,
  verifyEmail,
  getUserInfoByUid,
  getChannelInfo,
};
